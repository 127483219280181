var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.client)?_c('v-card',{staticClass:"fill-height",attrs:{"outlined":""}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-domain")]),_vm._v(" "+_vm._s(_vm.$t('client.clientDetail.servicesPurchased'))+" "),_c('v-spacer'),(_vm.clientProp.plan)?_c('div',{staticClass:"mr-3 body-1"},[_vm._v(" "+_vm._s(_vm.$t('client.clientDetail.expirationLicense'))+": "),_c('span',{class:{
          'expirated-date':
            _vm.clientProp.plan.expirationLicense.toDate() <= new Date(),
        }},[_vm._v(" "+_vm._s(_vm.$d(_vm.clientProp.plan.expirationLicense.toDate()))+" ")])]):_vm._e(),_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.getClientStatus(_vm.clientProp).color,"id":"enter-notif-detail"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.getClientStatus(_vm.clientProp).status))+" ")]),(_vm.clientProp.trialLicense)?_c('v-chip',{staticClass:"ma-2 white--text",attrs:{"color":"orange"}},[_vm._v(" "+_vm._s(_vm.$t('trialLicenseChip'))+" ")]):_vm._e()],1),_c('v-card-text',[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-start"},[(_vm.clientProp.userActivated)?_c('v-row',{attrs:{"align":"center"}},[(_vm.isAddaliaAdminUser)?_c('v-col',{staticClass:"mt-2",attrs:{"cols":"4"}},[_c('v-switch',{staticClass:"body-1 text-end pa-0 ma-0",attrs:{"label":_vm.$t('client.clientDetail.userActive'),"id":"activate-client"},on:{"change":function($event){_vm.dialogActivate = true}},model:{value:(_vm.toggleSwitch),callback:function ($$v) {_vm.toggleSwitch=$$v},expression:"toggleSwitch"}})],1):_vm._e(),(
                _vm.isAddaliaUser &&
                _vm.clientProp.estadoRenovacionLicencia &&
                _vm.clientProp.estadoRenovacionLicencia != 'renovacionOK' &&
                this.allowToPerformAction('changeLicenseRenewalStatus')
              )?_c('v-row',[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"8"}},[_c('v-select',{attrs:{"items":_vm.licenseRenewalStatusList,"label":_vm.$t('renewalStatusChange'),"disabled":_vm.clientProp.estado !== 'activo'},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t('clientRenewalStatus.' + item))+" ")]}},{key:"selection",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t('clientRenewalStatus.' + item))+" ")]}}],null,false,255013404),model:{value:(_vm.client.estadoRenovacionLicencia),callback:function ($$v) {_vm.$set(_vm.client, "estadoRenovacionLicencia", $$v)},expression:"client.estadoRenovacionLicencia"}})],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.confirmLoading,"disabled":!_vm.licenseRenewalStatusChanges || _vm.confirmLoading},on:{"click":function($event){return _vm.updateLicenseRenewalStatus()}}},[_vm._v(" "+_vm._s(_vm.$t('client.clientDetail.save'))+" ")])],1)],1):(
                _vm.clientProp.estadoRenovacionLicencia &&
                _vm.clientProp.estadoRenovacionLicencia != 'renovacionOK'
              )?_c('v-row',[_c('v-col',{staticClass:"mt-3 ml-6 mb-3",attrs:{"cols":"10"}},[_c('span',{staticClass:"body-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('renewalStatus'))+": ")])]),_c('span',{staticClass:"body-1 text-end"},[_vm._v(" "+_vm._s(_vm.$t( `clientRenewalStatus.${_vm.clientProp.estadoRenovacionLicencia}` ))+" ")])])],1):_vm._e()],1):(
              !_vm.clientProp.userActivated &&
              _vm.isAddaliaUser &&
              _vm.allowToPerformAction('changeUserStatus')
            )?_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-select',{attrs:{"items":_vm.userStatusList,"label":_vm.$t('statusChange'),"disabled":!_vm.currentStateValidForCurrentRol},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t('clientStatus.' + item))+" ")]}},{key:"selection",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t('clientStatus.' + item))+" ")]}}],null,false,1229829596),model:{value:(_vm.client.estado),callback:function ($$v) {_vm.$set(_vm.client, "estado", $$v)},expression:"client.estado"}})],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.confirmLoading,"disabled":!_vm.statusChanges || _vm.confirmLoading},on:{"click":function($event){return _vm.updateStatus()}}},[_vm._v(" "+_vm._s(_vm.$t('client.clientDetail.save'))+" ")])],1)],1):_vm._e(),(
              !_vm.clientProp.userActivated &&
              _vm.isAddaliaUser &&
              _vm.allowToPerformAction('changeUserStatus')
            )?_c('v-divider',{staticClass:"mb-3"}):_vm._e(),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"auto"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('client.clientDetail.notificationsActive'))+" "),(_vm.clientProp.notificacionesActivasEmpresa)?_c('v-icon',[_vm._v(" mdi-check ")]):_c('v-icon',[_vm._v(" mdi-close ")])],1)]),(_vm.clientProp.userActivated)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('cifsNotifications')))]),(_vm.notificationsCuotaPending)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-alert")])]}}],null,false,3053846940)},[_c('span',[_vm._v(_vm._s(_vm.$t('pendingActivationPackages')))])]):_vm._e()],1),(_vm.clientProp.plan)?_c('v-col',{attrs:{"cols":"8"}},[_c('AppPlanProgress',{attrs:{"current":_vm.cifsCountNotifications,"max":_vm.clientProp.plan.notificationsMaxCifs,"disabled":!_vm.clientProp.notificacionesActivasEmpresa,"textSize":"h1","icon":"mdi-city"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.clientProp.tipoEmpresa != 'ASESORIACOLECTIVO')?_c('UpgradeCuotaDialog',{attrs:{"disabled":!_vm.clientProp.userActivated ||
                    !_vm.allowToPerformAction('createInvoice') ||
                    _vm.notificationsCuotaPending ||
                    _vm.renewalPending,"client":_vm.clientProp,"cuotaServicio":_vm.clientProp.tipoEmpresa == 'EMPRESA'
                      ? this.$store.state.appBilling.empresaNotificacionesCif
                      : this.$store.state.appBilling.despachoNotificacionesCif,"typeProp":_vm.clientProp.notificacionesActivas
                      ? 'upgrade'
                      : 'activation',"assetName":"notificationsCuota"}}):_c('UpgradeCuotaCollectiveDialog',{attrs:{"clientProp":_vm.client}})],1)],1),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('packagesNotifications')))]),(_vm.notificationsPackagesPending)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-alert")])]}}],null,false,3053846940)},[_c('span',[_vm._v(_vm._s(_vm.$t('pendingActivationPackages')))])]):_vm._e()],1),(_vm.clientProp.plan)?_c('v-col',{attrs:{"cols":"8"}},[_c('AppPlanProgress',{attrs:{"current":_vm.clientProp.plan.notificationsUsed,"max":_vm.clientProp.plan.notificationsMax,"disabled":!_vm.clientProp.notificacionesActivasEmpresa,"textSize":"h1","icon":"mdi-mailbox"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.clientProp.tipoEmpresa != 'ASESORIACOLECTIVO')?_c('UpgradePackagesDialog',{attrs:{"disabled":!_vm.clientProp.userActivated ||
                    !_vm.allowToPerformAction('createInvoice') ||
                    _vm.notificationsPackagesPending ||
                    _vm.renewalPending,"client":_vm.clientProp,"prices":_vm.clientProp.tipoEmpresa == 'EMPRESA'
                      ? _vm.$store.state.appBilling
                          .empresaNotificacionesPaquetesCuota
                      : _vm.$store.state.appBilling
                          .despachoNotificacionesPaquetesCuota,"packagesList":_vm.clientProp.tipoEmpresa == 'EMPRESA'
                      ? _vm.$store.state.appBilling.empresaNotificacionesPaquetes
                      : _vm.$store.state.appBilling.despachoNotificacionesPaquetes,"assetName":"notificationsPackages","label":_vm.$t('numberOfNotifications'),"typeProp":_vm.clientProp.notificacionesActivas
                      ? 'upgrade'
                      : 'activation'}}):_c('UpgradeCuotaCollectiveDialog',{attrs:{"clientProp":_vm.client}})],1)],1)],1):_vm._e(),_c('br'),(_vm.clientProp.tipoEmpresa != 'ASESORIACOLECTIVO')?_c('v-col',{attrs:{"cols":"auto"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('client.clientDetail.signaturesActive'))+" "),(_vm.clientProp.firmasActivasEmpresa)?_c('v-icon',[_vm._v(" mdi-check ")]):_c('v-icon',[_vm._v(" mdi-close ")])],1)]):_vm._e(),(
              _vm.clientProp.userActivated &&
              _vm.clientProp.tipoEmpresa != 'ASESORIACOLECTIVO'
            )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('certificatesSignatures')))]),(_vm.signaturesCertsPackagesPending)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-alert")])]}}],null,false,3053846940)},[_c('span',[_vm._v(_vm._s(_vm.$t('pendingActivationPackages')))])]):_vm._e()],1),(_vm.clientProp.plan)?_c('v-col',{attrs:{"cols":"8"}},[_c('AppPlanProgress',{attrs:{"current":_vm.certsCountSignatures,"max":_vm.clientProp.plan.signaturesMaxCerts,"disabled":!_vm.clientProp.firmasActivasEmpresa,"textSize":"h1","icon":"mdi-account-key"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_c('UpgradePackagesDialog',{attrs:{"disabled":!_vm.clientProp.userActivated ||
                    !_vm.allowToPerformAction('createInvoice') ||
                    _vm.signaturesCertsPackagesPending ||
                    _vm.renewalPending,"client":_vm.clientProp,"prices":_vm.clientProp.tipoEmpresa == 'EMPRESA'
                      ? _vm.$store.state.appBilling.empresaFirmasCertificadosCuota
                      : _vm.$store.state.appBilling
                          .despachoFirmasCertificadosCuota,"packagesList":_vm.clientProp.tipoEmpresa == 'EMPRESA'
                      ? _vm.$store.state.appBilling.empresaFirmasCertificados
                      : _vm.$store.state.appBilling.despachoFirmasCertificados,"assetName":"signaturesCertsPackages","label":_vm.$t('numberCertificates'),"typeProp":_vm.clientProp.firmasActivas ? 'upgrade' : 'activation'}})],1)],1),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('packagesSignatures')))]),(_vm.signaturesPackagesPending)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-alert")])]}}],null,false,3053846940)},[_c('span',[_vm._v(_vm._s(_vm.$t('pendingActivationPackages')))])]):_vm._e()],1),(_vm.clientProp.plan)?_c('v-col',{attrs:{"cols":"8"}},[_c('AppPlanProgress',{attrs:{"current":_vm.clientProp.plan.signaturesUsed,"max":_vm.clientProp.plan.signaturesMax,"disabled":!_vm.clientProp.firmasActivasEmpresa,"textSize":"h1","icon":"mdi-draw"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_c('UpgradePackagesDialog',{attrs:{"disabled":!_vm.clientProp.userActivated ||
                    !_vm.allowToPerformAction('createInvoice') ||
                    _vm.signaturesPackagesPending ||
                    _vm.renewalPending,"client":_vm.clientProp,"prices":_vm.clientProp.tipoEmpresa == 'EMPRESA'
                      ? _vm.$store.state.appBilling.empresaFirmasPaquetesCuota
                      : _vm.$store.state.appBilling.despachoFirmasPaquetesCuota,"packagesList":_vm.clientProp.tipoEmpresa == 'EMPRESA'
                      ? _vm.$store.state.appBilling.empresaFirmasPaquetes
                      : _vm.$store.state.appBilling.despachoFirmasPaquetes,"assetName":"signaturesPackages","label":_vm.$t('numberOfSignatures'),"typeProp":_vm.clientProp.firmasActivas ? 'upgrade' : 'activation'}})],1)],1)],1):_vm._e(),(_vm.clientProp.tipoEmpresa != 'ASESORIACOLECTIVO')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"auto"}},[_c('h2',[_vm._v(_vm._s(_vm.$t('client.clientDetail.additionalServices'))+":")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('client.clientDetail.multiFactor'))+" "),(_vm.clientProp.allowed2FA)?_c('v-icon',[_vm._v(" mdi-check ")]):_c('v-icon',[_vm._v(" mdi-close ")])],1)]),(
                      _vm.clientProp.allowed2FA &&
                      _vm.allowToPerformAction('changeClientInformation')
                    )?_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"8"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.openSetupDialog('setup2FA', _vm.clientProp.multiFactor)}}},[_vm._v(" "+_vm._s(_vm.$t('client.clientDetail.setup2FA'))+" ")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('client.clientDetail.absencesManagement'))+" "),(_vm.clientProp.allowedAbsenceManagement)?_c('v-icon',[_vm._v(" mdi-check ")]):_c('v-icon',[_vm._v(" mdi-close ")])],1)]),(false)?[(
                        _vm.clientProp.allowedAbsenceManagement &&
                        _vm.allowToPerformAction('changeClientInformation')
                      )?_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"8"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.openSetupDialog('setupAbsences', [
                            _vm.clientProp.allowedAbsenceManagement,
                          ])}}},[_vm._v(" "+_vm._s(_vm.$t('client.clientDetail.setupAbsencesShort'))+" ")])],1):_vm._e()]:_vm._e()],2)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('client.clientDetail.customBrand'))+" "),(_vm.clientProp.allowedCustomBrand)?_c('v-icon',[_vm._v(" mdi-check ")]):_c('v-icon',[_vm._v(" mdi-close ")])],1)]),(
                  _vm.clientProp.allowedCustomBrand &&
                  _vm.allowToPerformAction('changeClientInformation')
                )?_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"8"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.openSetupDialog(
                      'setupCustomBrand',
                      _vm.clientProp.customBrand
                    )}}},[_vm._v(" "+_vm._s(_vm.$t('client.clientDetail.setupCustomBrand'))+" ")])],1):_vm._e()],1)],1):_vm._e()],1)])],1)],1),_c('v-card-actions',[(
        _vm.clientProp.userActivated &&
        _vm.allowToPerformAction('createRenew') &&
        !_vm.renewalPending &&
        !_vm.upgradesPending &&
        _vm.clientProp.tipoEmpresa != 'ASESORIACOLECTIVO'
      )?_c('ClientRenew',{attrs:{"minimumSignaturesCifs":_vm.certsCountSignatures,"minimumNotificationsCifs":_vm.cifsCountNotifications,"client":_vm.clientProp,"typeProp":"renewal"}}):_vm._e(),_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.dialogActivate),callback:function ($$v) {_vm.dialogActivate=$$v},expression:"dialogActivate"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_c('v-container',[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[(_vm.clientProp.disabled)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('confirmActivateTitle'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('confirmDeactivateTitle'))+" ")]),_c('v-btn',{attrs:{"fab":"","small":"","plain":"","disabled":_vm.confirmLoading},on:{"click":_vm.handleCloseDialog}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)],1)],1),_c('br'),(_vm.clientProp.disabled)?_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('confirmActivateText'))+" "),(
              _vm.isAddaliaUser &&
              (!_vm.clientProp.redmineKey || !_vm.clientProp.redmineProject)
            )?_c('div',{staticClass:"noteBoxes typeOrange",staticStyle:{"display":"flex"}},[_c('img',{staticClass:"note-box-icon",attrs:{"src":require("@/assets/alert.png")}}),_c('div',[_vm._v(_vm._s(_vm.$t('redmineMissing')))])]):_vm._e()]):_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('confirmDeactivateText'))+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(_vm.clientProp.disabled)?_c('v-btn',{attrs:{"color":"primary","text":"","id":"confirm-activate-client","loading":_vm.confirmLoading,"disabled":_vm.confirmLoading},on:{"click":function($event){return _vm.confirmActivation()}}},[_vm._v(" "+_vm._s(_vm.$t('confirmActivateAcceptance'))+" ")]):_c('v-btn',{attrs:{"color":"primary","text":"","id":"confirm-deactivate-client","loading":_vm.confirmLoading,"disabled":_vm.confirmLoading},on:{"click":function($event){return _vm.deactivateUser()}}},[_vm._v(" "+_vm._s(_vm.$t('confirmActivateAcceptance'))+" ")])],1)],1)],1)],1),(_vm.setupDialog)?_c('SetupDialog',{attrs:{"userId":_vm.clientProp.id,"action":_vm.setupAction,"args":_vm.setupArgs},on:{"closeSetupDialog":_vm.closeSetupDialog}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }