<template>
  <v-container class="pa-0" fluid no-glutters>
    <v-row justify="space-around">
      <v-col
        v-for="item in services"
        v-bind:key="item.concept"
        cols="12"
        md="6"
        :lg="item.selected ? 6 : isConfiguration ? 4 : 6"
      >
        <!-- :md="isConfiguration ? 9 : 5"
        :sm="isConfiguration ? 9 : 5" -->
        <v-card class="pa-2">
          <v-container>
            <v-row @click="switchSelected(item)" justify="space-between">
              <v-col cols="auto">
                <v-icon
                  v-if="!isConfiguration && item.selected"
                  color="primary"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-else-if="!isConfiguration"
                  :id="
                    item.concept == 'multiFactor'
                      ? 'select-2FA'
                      : 'select-custom-brand'
                  "
                >
                  mdi-circle-outline
                </v-icon>

                <v-icon x-large>
                  {{ getIcon(item) }}
                </v-icon>
              </v-col>
              <v-col cols="auto">
                <h2>{{ $t('client.additionalServices.' + item.concept) }}</h2>
              </v-col>
            </v-row>

            <v-row v-if="isConfiguration" justify="center">
              <v-col cols="6">
                <v-text-field
                  v-if="item.concept === 'multiFactor'"
                  v-model="multiFactorPriceModel"
                  :rules="[notEmptyNumber]"
                  :placeholder="$t('price') + '(€)'"
                >
                  €
                </v-text-field>
                <v-text-field
                  v-else-if="item.concept === 'customBrand'"
                  v-model="customBrandPriceModel"
                  :rules="[notEmptyNumber]"
                  :placeholder="$t('price') + '(€)'"
                >
                  €
                </v-text-field>
                <v-text-field
                  v-else-if="item.concept === 'absences'"
                  v-model="absencesPriceModel"
                  :rules="[notEmptyNumber]"
                  :placeholder="$t('price') + '(€)'"
                >
                  €
                </v-text-field>

                <v-btn
                  v-if="getButtonComputed(item)"
                  @click.stop="changeCuotaPrice(item.concept)"
                  class="ma-0 pa-0"
                  plain
                >
                  {{ $t('client.customBrand.save') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-expand-transition v-else>
              <v-row
                v-if="item.selected"
                align="center"
                justify="space-between"
              >
                <v-col cols="7">
                  <v-slider
                    :disabled="isChannelUser || isTrialLicense"
                    class="mt-5"
                    v-model="item.discountPercentage"
                    :label="$t('client.cuotaCard.discount')"
                    :thumb-color="$vuetify.theme.dark ? 'white' : 'black'"
                  >
                    <template v-slot:thumb-label="{ value }">
                      {{ value }}%
                    </template>
                  </v-slider>
                </v-col>
                <v-col cols="auto">
                  <h3>
                    {{ $n(item.price, 'i18nAmount') }}€ -
                    {{ item.discountPercentage }}% =
                    {{
                      $n(
                        item.price -
                          (item.price * item.discountPercentage) / 100,
                        'i18nAmount'
                      )
                    }}
                    €
                  </h3>
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-container>
        </v-card>
      </v-col>

      <!-- alerta control de cliente no permite desactivar Gestión de ausencias cuando estan en uso -->
      <div
        style="display: flex; justify-content: center; width: 100%"
        v-if="clientAbsencesBlocked"
      >
        <v-alert
          type="error"
          color="red"
          style="max-width: 80%; text-align: left; font-size: 18px"
        >
          <div>{{ $t('clientAbsenceCannotBeDisabledLine1') }}</div>
          <div>
            {{
              $t('clientAbsenceCannotBeDisabledLine2', {
                total: operatorsWithAbsences.length,
              })
            }}
          </div>
          <div>
            {{ $t('clientAbsenceCannotBeDisabledLine3') }}
          </div>
        </v-alert>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import rules from '@/utils/rules'
import { mapGetters, mapMutations } from 'vuex'
import { updateBilling } from '@/services/billing-service'
import { checkScheduledAbsences } from '@/services/clients-service'

export default {
  props: {
    multiFactorPrice: {
      type: Number,
    },
    customBrandPrice: {
      type: Number,
    },
    absencesPrice: {
      type: Number,
    },
    selected2FA: {
      type: Boolean,
    },
    selectedCustomBrand: {
      type: Boolean,
    },
    selectedAbsences: {
      type: Boolean,
    },
    clientId: {
      type: String,
    },
    fixedDiscount: {
      type: Number,
      default: 0,
    },
    isConfiguration: {
      type: Boolean,
      default: false,
    },
    companyType: {
      type: String,
    },
    isTrialLicense: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.multiFactorPriceModel = this.multiFactorPrice
    this.customBrandPriceModel = this.customBrandPrice
    this.absencesPriceModel = this.absencesPrice
  },
  data() {
    return {
      multiFactorPriceModel: this.multiFactorPrice,
      customBrandPriceModel: this.customBrandPrice,
      absencesPriceModel: this.absencesPrice,
      services: [
        {
          selected: this.selected2FA ? this.selected2FA : false,
          concept: 'multiFactor',
          price: this.multiFactorPrice,
          discountAmount: 0,
          discountPercentage: this.fixedDiscount,
        },
        {
          selected: this.selectedCustomBrand ? this.selectedCustomBrand : false,
          concept: 'customBrand',
          price: this.customBrandPrice,
          discountAmount: 0,
          discountPercentage: this.fixedDiscount,
        },
        {
          selected: this.selectedAbsences ? this.selectedAbsences : false,
          concept: 'absences',
          price: this.absencesPrice,
          discountAmount: 0,
          discountPercentage: this.fixedDiscount,
        },
      ],
      clientAbsencesBlocked: false,
      operatorsWithAbsences: [],
    }
  },
  methods: {
    ...rules,
    ...mapMutations(['setSnackbar']),
    async changeCuotaPrice(concept) {
      try {
        switch (concept) {
          case 'multiFactor':
            if (this.companyType == 'EMPRESA')
              await updateBilling({
                empresa2FACuota: parseInt(this.multiFactorPriceModel),
              })
            if (this.companyType == 'ASESORIA')
              await updateBilling({
                despacho2FACuota: parseInt(this.multiFactorPriceModel),
              })
            break

          case 'customBrand':
            if (this.companyType == 'EMPRESA')
              await updateBilling({
                empresaBrandCuota: parseInt(this.customBrandPriceModel),
              })
            if (this.companyType == 'ASESORIA')
              await updateBilling({
                despachoBrandCuota: parseInt(this.customBrandPriceModel),
              })
            break

          case 'absences':
            if (this.companyType == 'EMPRESA')
              await updateBilling({
                empresaAbsencesCuota: parseInt(this.absencesPriceModel),
              })
            if (this.companyType == 'ASESORIA')
              await updateBilling({
                despachoAbsencesCuota: parseInt(this.absencesPriceModel),
              })
            break

          default:
            return
        }

        const message = this.$t('updatePriceSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        const message = this.$t('updatePriceError')
        this.setSnackbar({ position: 'top', type: 'error', message })
      } finally {
        // Limpiamos el precio
        this.multiFactorPriceModel = this.multiFactorPrice
        this.customBrandPriceModel = this.customBrandPrice
        this.absencesPriceModel = this.absencesPrice
      }
    },
    getIcon(item) {
      switch (item.concept) {
        case 'multiFactor':
          return 'mdi-two-factor-authentication'

        case 'customBrand':
          return 'mdi-palette'

        case 'absences':
          return 'mdi-calendar-month'

        default:
          return
      }
    },
    getButtonComputed(item) {
      switch (item.concept) {
        case 'multiFactor':
          return this.checkButton2FARulesComputed

        case 'customBrand':
          return this.checkButtonCustomBrandRulesComputed

        case 'absences':
          return this.checkButtonAbsencesRulesComputed

        default:
          return false
      }
    },
    async switchSelected(item) {
      item.selected = !item.selected
      if (item.concept === 'absences') {
        this.clientAbsencesBlocked = false
        if (this.selectedAbsences != item.selected) {
          // si estamos desactivando la opcion (originalmente activa) de gestionar ausencias, entonces
          // hay que comprobar que esté 'en uso', es decir, que tenga programadas ausencias.
          // En esta Fase I vamos a mostrar un aviso, impidiendo su desactivacion hasta que el Cliente elimine todas
          // las ausencias programadas (para que no queden descontroladas). En una fase posterior a futuro podriamos
          // preguntar y, si se confirma, entonces proceder desde aqui, app management, al borrado de todas las ausencias
          // programdas que tenga creadas el Cliente.
          //
          this.operatorsWithAbsences = await this.areThereScheduledAbsences()
          this.clientAbsencesBlocked = this.operatorsWithAbsences.length > 0
          //if (this.operatorsWithAbsences.length) {
          //item.selected = !item.selected // restaura el valor
          //}
        }
        this.$emit('invalidRenew', this.clientAbsencesBlocked)
      }
    },
    async areThereScheduledAbsences() {
      if (!this.clientId) return []
      const operatorsWithAbsences = await checkScheduledAbsences(this.clientId)
      return operatorsWithAbsences
    },
  },
  computed: {
    ...mapGetters(['isChannelUser']),
    checkButton2FARulesComputed() {
      return (
        this.multiFactorPriceModel !== '' &&
        this.multiFactorPriceModel !== undefined &&
        this.multiFactorPriceModel !== null &&
        this.multiFactorPriceModel != this.multiFactorPrice &&
        this.multiFactorPriceModel > 0
      )
    },
    checkButtonCustomBrandRulesComputed() {
      return (
        this.customBrandPriceModel !== '' &&
        this.customBrandPriceModel !== undefined &&
        this.customBrandPriceModel !== null &&
        this.customBrandPriceModel != this.customBrandPrice &&
        this.customBrandPriceModel > 0
      )
    },
    checkButtonAbsencesRulesComputed() {
      return (
        this.absencesPriceModel !== '' &&
        this.absencesPriceModel !== undefined &&
        this.absencesPriceModel !== null &&
        this.absencesPriceModel != this.absencesPrice &&
        this.absencesPriceModel > 0
      )
    },
    servicesComputed() {
      this.services.forEach((service) => {
        service.discountAmount =
          (service.price * service.discountPercentage) / 100
      })
      return this.services
    },
  },
  mounted() {
    this.$emit('customServices', this.services)
  },
  watch: {
    servicesComputed: {
      handler(newValue) {
        this.$emit('customServices', newValue)
      },
      deep: true, // Activa la vigilancia profunda
    },
    multiFactorPrice: function (newValue) {
      this.services[0].price = newValue
    },
    customBrandPrice: function (newValue) {
      this.services[1].price = newValue
    },
    absencesPrice: function (newValue) {
      this.services[2].price = newValue
    },
    // Sincroniza slider value si fixedDiscount cambia en el padre al activar/desactivar el checkbox de licencia trial
    fixedDiscount(newVal) {
      this.services.forEach((service) => {
        service.discountPercentage = newVal
      })
    },
    selectedAbsences(newVal) {
      // si cambia externamente el valor del atributo boolean 'allowedAbsenceManagement' entonces se debe
      // reflejar aquí en la vble local si está seleccionado o no
      const item = this.services.find((el) => el.concept === 'absences')
      if (item) {
        item.selected = newVal
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.selected {
  border: 1px solid var(--v-primary-base);
}
</style>
