<template>
  <v-card class="pa-4">
    <h2>{{ $t('client.createClient.createClient') }}</h2>
    <v-container>
      <v-form ref="form" lazy-validation>
        <v-row class="pa-3" justify="center" align="center">
          <v-row justify="center" align="center">
            <v-col cols="12" md="4">
              <v-select
                v-model="cliente.tipoEmpresa"
                :items="clientTypes"
                :label="$t('client.createClient.userType')"
                prepend-icon="mdi-domain"
                :rules="[notEmpty]"
                :disabled="confirmLoading"
                id="select-tipo-empresa"
              ></v-select>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="cliente.empresa"
                :label="$t('client.createClient.companyName')"
                prepend-icon="mdi-account-circle"
                :rules="[notEmpty]"
                :disabled="confirmLoading"
                id="empresa-input"
              ></v-text-field>
            </v-col>

            <v-col cols="4" md="2">
              <v-select
                v-model="cliente.tipoDocIdentidad"
                :items="docTypes"
                :label="$t('client.createClient.idNumberType')"
                prepend-icon="mdi-card-account-details"
                :rules="[notEmpty]"
                :disabled="confirmLoading"
                id="tipo-doc-select"
              >
              </v-select>
            </v-col>
            <v-col cols="8" md="2">
              <v-text-field
                v-model="cliente.numeroDocIdentidad"
                @input="cliente.numeroDocIdentidad = $event.toUpperCase()"
                :label="$t('client.createClient.idNumber')"
                prepend-icon="mdi-id-card"
                :rules="[
                  notEmpty,
                  idNumber(
                    cliente.tipoDocIdentidad,
                    cliente.numeroDocIdentidad
                  ),
                ]"
                :disabled="confirmLoading"
                id="doc-identidad-input"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="3">
              <v-text-field
                v-model="cliente.nombre"
                :label="$t('client.createClient.name')"
                prepend-icon="mdi-account"
                :rules="[notEmpty]"
                :disabled="confirmLoading"
                id="name-input"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="5">
              <v-text-field
                v-model="cliente.apellidos"
                :label="$t('client.createClient.surname')"
                prepend-icon="mdi-account"
                :rules="[notEmpty]"
                :disabled="confirmLoading"
                id="surname-input"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="cliente.email"
                :label="$t('client.createClient.email')"
                type="email"
                prepend-icon="mdi-email"
                :rules="emailRules"
                :disabled="confirmLoading"
                id="email-input"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="cliente.telefono"
                :label="$t('client.createClient.phone')"
                :hint="$t('hintPhone')"
                prepend-icon="mdi-cellphone-basic"
                :rules="[phoneNumber]"
                dense
                autocomplete="false"
                @keydown.space.prevent
                :disabled="confirmLoading"
                id="phone-input"
              >
              </v-text-field>
            </v-col>

            <v-row>
              <v-col cols="12" lg="12">
                <TermsHaveAcceptCard
                  :isSelected="termsHaveAcceptSelected"
                  @selectTermsHaveAcceptEvent="selectTermsHaveAcceptEvent"
                  :contractModelMap="contractModelMap"
                  @contractModel="contractModelEvent"
                />
              </v-col>
            </v-row>
          </v-row>
        </v-row>

        <v-row v-if="isAddaliaUser">
          <v-col cols="12" lg="12">
            <div class="text-start pl-0">
              <v-row class="ml-1 mt-1 ps-0">
                <v-col cols="auto" class="pl-0">
                  <h2>{{ $t('trialLicense') }}</h2>

                  <v-checkbox
                    v-model="cliente.trialLicense"
                    class="ma-0 pa-0 large-checkbox custom-label-color"
                    :label="$t('trialLicenseCheckboxLabel')"
                    dense
                    :disabled="confirmLoading"
                    id="trialLicense-checkbox"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <CuotaCard
              :fixedDiscount="calculateDiscount()"
              :price="
                cliente.tipoEmpresa == 'Empresa'
                  ? empresaAppCuota
                  : despachoAppCuota
              "
              :isTrialLicense="cliente.trialLicense"
              @goodOkCuota="goodOkCuotaEvent"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12">
            <ProductNotificationsCard
              :fixedDiscount="calculateDiscount()"
              @selectProductNotificationsEvent="selectProductNotificationsEvent"
              :isSelected="notificationsSelected"
              :prices="
                cliente.tipoEmpresa == 'Empresa'
                  ? empresaNotificacionesPaquetesCuota
                  : despachoNotificacionesPaquetesCuota
              "
              :packagesList="
                cliente.tipoEmpresa == 'Empresa'
                  ? empresaNotificacionesPaquetes
                  : despachoNotificacionesPaquetes
              "
              :cuotaServicio="
                cliente.tipoEmpresa == 'Empresa'
                  ? empresaNotificacionesCif
                  : despachoNotificacionesCif
              "
              :isTrialLicense="cliente.trialLicense"
              @notificationsPackages="notificationsPackagesEvent"
              @notificationsCuota="notificationsCuotaEvent"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12">
            <ProductSignaturesCard
              :fixedDiscount="calculateDiscount()"
              @selectProductSignaturesEvent="selectProductSignaturesEvent"
              :isSelected="signaturesSelected"
              :pricesCerts="
                cliente.tipoEmpresa == 'Empresa'
                  ? empresaFirmasCertificadosCuota
                  : despachoFirmasCertificadosCuota
              "
              :packagesListCerts="
                cliente.tipoEmpresa == 'Empresa'
                  ? empresaFirmasCertificados
                  : despachoFirmasCertificados
              "
              :pricesSignatures="
                cliente.tipoEmpresa == 'Empresa'
                  ? empresaFirmasPaquetesCuota
                  : despachoFirmasPaquetesCuota
              "
              :packagesListSignatures="
                cliente.tipoEmpresa == 'Empresa'
                  ? empresaFirmasPaquetes
                  : despachoFirmasPaquetes
              "
              :isTrialLicense="cliente.trialLicense"
              @signaturesCertsPackages="signaturesCertsPackagesEvent"
              @signaturesPackages="signaturesPackagesEvent"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <AdditionalServices
              :fixedDiscount="calculateDiscount()"
              :multiFactorPrice="
                cliente.tipoEmpresa == 'Empresa'
                  ? empresa2FACuota
                  : despacho2FACuota
              "
              :customBrandPrice="
                cliente.tipoEmpresa == 'Empresa'
                  ? empresaBrandCuota
                  : despachoBrandCuota
              "
              :absencesPrice="
                cliente.tipoEmpresa == 'Empresa'
                  ? empresaAbsencesCuota
                  : despachoAbsencesCuota
              "
              :isTrialLicense="cliente.trialLicense"
              @customServices="customServicesEvent"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <BillingResume
              :concepts="conceptosContratados"
              :includeDates="false"
              :showSum="true"
              :client="{ estado: 'creating' }"
              :isTrialLicense="cliente.trialLicense"
            />
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <span v-if="confirmLoading">
            <span>{{ $t('client.createClient.creatingClient') }}</span>
            <v-progress-linear indeterminate color="black"></v-progress-linear>
          </span>
          <v-col cols="12">
            <v-btn
              color="primary"
              :loading="confirmLoading"
              :disabled="confirmLoading"
              @click="createClient"
              id="register"
            >
              {{ $t('client.createClient.register') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import ProductNotificationsCard from '@/components/client/createClient/ProductNotificationsCard.vue'
import ProductSignaturesCard from '@/components/client/createClient/ProductSignaturesCard.vue'
import CuotaCard from '@/components/client/createClient/CuotaCard.vue'
import TermsHaveAcceptCard from '@/components/client/createClient/TermsHaveAcceptCard.vue'
import BillingResume from '@/components/client/BillingResume.vue'
import AdditionalServices from '@/components/client/createClient/AdditionalServices.vue'
import getErrorText from '@/utils/get-error-text'
import { createEmpresaFirestore } from '@/services/clients-service'
import { allowToPerformAction } from '@/services/users-service'
import { mapGetters, mapMutations } from 'vuex'
import rules from '@/utils/rules'
import { toLocaleISOString } from '@/utils/date-utils'

export default {
  components: {
    CuotaCard,
    ProductNotificationsCard,
    ProductSignaturesCard,
    TermsHaveAcceptCard,
    BillingResume,
    AdditionalServices,
  },
  data() {
    return {
      emailRules: [rules.required, rules.email],
      clientTypes: ['Asesoria', 'Empresa'],
      termsHaveAcceptSelected:
        this.$store.state.user.type == 'addalia' ? true : false, // por defecto activado, el cliente debe aceptar los terminos del servicio
      contractModelSelected: '',
      notificationsSelected: false,
      signaturesSelected: false,
      confirmLoading: false,
      docTypes: ['DNI', 'NIE', 'CIF', 'NIF-M'],
      cliente: {
        tipoEmpresa: 'Asesoria',
        empresa: '',
        nombre: '',
        apellidos: '',
        email: '',
        telefono: '',
        tipoDocIdentidad: '',
        numeroDocIdentidad: '',
        trialLicense: false,
      },
      // Modelos de contratos para los Terminos del servicio
      contractModelMap: this.$store.state.appLegal,
      // Paquetes EMPRESA
      empresaAppCuota: this.$store.state.appBilling.empresaAppCuota,
      empresaNotificacionesCif:
        this.$store.state.appBilling.empresaNotificacionesCif,
      empresaNotificacionesPaquetesCuota:
        this.$store.state.appBilling.empresaNotificacionesPaquetesCuota,
      empresaNotificacionesPaquetes:
        this.$store.state.appBilling.empresaNotificacionesPaquetes, //para simplificar el algoritmo de restarNumerosHastaMenor
      empresaFirmasCertificadosCuota:
        this.$store.state.appBilling.empresaFirmasCertificadosCuota,
      empresaFirmasCertificados:
        this.$store.state.appBilling.empresaFirmasCertificados, //para simplificar el algoritmo de restarNumerosHastaMenor
      empresaFirmasPaquetesCuota:
        this.$store.state.appBilling.empresaFirmasPaquetesCuota,
      empresaFirmasPaquetes: this.$store.state.appBilling.empresaFirmasPaquetes, //para simplificar el algoritmo de restarNumerosHastaMenor
      empresa2FACuota: this.$store.state.appBilling.empresa2FACuota,
      empresaBrandCuota: this.$store.state.appBilling.empresaBrandCuota,
      empresaAbsencesCuota:
        this.$store.state.appBilling?.empresaAbsencesCuota ?? 1, //TODO GOODOK-1954 de momento el precio es 1

      //Paquetes DESPACHO
      despachoAppCuota: this.$store.state.appBilling.despachoAppCuota,
      despachoNotificacionesCif:
        this.$store.state.appBilling.despachoNotificacionesCif,
      despachoNotificacionesPaquetesCuota:
        this.$store.state.appBilling.despachoNotificacionesPaquetesCuota,
      despachoNotificacionesPaquetes:
        this.$store.state.appBilling.despachoNotificacionesPaquetes, //para simplificar el algoritmo de restarNumerosHastaMenor
      despachoFirmasCertificadosCuota:
        this.$store.state.appBilling.despachoFirmasCertificadosCuota,
      despachoFirmasCertificados:
        this.$store.state.appBilling.despachoFirmasCertificados, //para simplificar el algoritmo de restarNumerosHastaMenor
      despachoFirmasPaquetesCuota:
        this.$store.state.appBilling.despachoFirmasPaquetesCuota,
      despachoFirmasPaquetes:
        this.$store.state.appBilling.despachoFirmasPaquetes, //para simplificar el algoritmo de restarNumerosHastaMenor
      despacho2FACuota: this.$store.state.appBilling.despacho2FACuota,
      despachoBrandCuota: this.$store.state.appBilling.despachoBrandCuota,
      despachoAbsencesCuota:
        this.$store.state.appBilling?.despachoAbsencesCuota ?? 1, //TODO GOODOK-1954 de momento el precio es 1

      //datos para la factura final
      goodOkCuota: {
        price: 0,
        discountAmount: 0,
        discountPercentage: 0,
      },
      notificationsCuota: {
        price: 0,
        discountAmount: 0,
        discountPercentage: 0,
      },
      notificationsPackages: {
        price: 0,
        discountAmount: 0,
        discountPercentage: 0,
      },
      signaturesCertsPackages: {
        price: 0,
        discountAmount: 0,
        discountPercentage: 0,
      },
      signaturesPackages: {
        price: 0,
        discountAmount: 0,
        discountPercentage: 0,
      },
      securityPackage: {
        price: 0,
        discountAmount: 0,
        discountPercentage: 0,
      },
      customBrandPackage: {
        price: 0,
        discountAmount: 0,
        discountPercentage: 0,
      },
      absencesPackage: {
        price: 0,
        discountAmount: 0,
        discountPercentage: 0,
      },
    }
  },
  computed: {
    ...mapGetters(['isAddaliaUser', 'isDistributorUser']),
    conceptosContratados() {
      return [
        this.goodOkCuota,
        this.notificationsCuota,
        this.notificationsPackages,
        this.signaturesCertsPackages,
        this.signaturesPackages,
        this.securityPackage,
        this.customBrandPackage,
        this.absencesPackage,
      ]
    },
  },
  methods: {
    ...mapMutations(['setSnackbar', 'setLoading', 'setNotFound']),
    ...rules,
    findInvalidFields() {
      // Recorre los campos para encontrar cual o cuales no son validos y mostrar un mensaje al usuario
      let messages = []
      // Título del mensaje de error
      messages.push(this.$t('client.createClient.invalidFields'))
      messages.push('') // Salto de línea
      this.$refs.form.inputs.forEach((input) => {
        if (!input.valid) {
          const fieldName = input.name || input.label
          messages.push('- ' + fieldName)
        }
      })
      const message = messages.join('\n')
      this.setSnackbar({ position: 'top', type: 'warning', message })
    },
    calculateDiscount() {
      this.calculatedDiscount =
        this.cliente.trialLicense == true
          ? 100
          : this.isDistributorUser
          ? this.$store.state.user.percent
          : 0
      return this.calculatedDiscount
    },
    async createClient() {
      if (!allowToPerformAction(this.$store.state.user.rol, 'createClient')) {
        const message = this.$t('insuficientPermissions')
        this.setSnackbar({ position: 'top', type: 'error', message })
        return
      }

      if (!this.$refs.form?.validate()) {
        this.findInvalidFields()
        return
      }

      this.setLoading(true)

      try {
        this.confirmLoading = true

        const dataCreate = {
          tipoEmpresa: this.cliente.tipoEmpresa,
          empresa: this.cliente.empresa,
          tipoDocIdentidad: this.cliente.tipoDocIdentidad,
          numeroDocIdentidad: this.cliente.numeroDocIdentidad,
          nombre: this.cliente.nombre,
          apellidos: this.cliente.apellidos,
          email: this.cliente.email,
          telefono: this.cliente.telefono,
          notificacionesActivas: false,
          firmasActivas: false,
          channelId: this.isAddaliaUser
            ? undefined
            : this.$store.state.user.parentRef?.id || this.$store.state.user.id,
          allowed2FA: this.conceptosContratados.find(
            ({ concept }) => concept === 'multiFactor'
          )
            ? this.conceptosContratados.find(
                ({ concept }) => concept === 'multiFactor'
              ).selected
            : false,
          allowedCustomBrand: this.conceptosContratados.find(
            ({ concept }) => concept === 'customBrand'
          )
            ? this.conceptosContratados.find(
                ({ concept }) => concept === 'customBrand'
              ).selected
            : false,
          allowedAbsenceManagement: this.conceptosContratados.find(
            ({ concept }) => concept === 'absences'
          )
            ? this.conceptosContratados.find(
                ({ concept }) => concept === 'absences'
              ).selected
            : false,
        }

        dataCreate.planBilling = {}

        const conceptos = [
          'goodOkCuota',
          'notificationsCuota',
          'notificationsPackages',
          'signaturesCertsPackages',
          'signaturesPackages',
          'multiFactor',
          'customBrand',
          'absences',
        ]

        // Creamos el Objeto de Objetos dataCreate.planBilling
        dataCreate.planBilling = conceptos.reduce((subObjeto, concepto) => {
          const conceptoContratado = this.conceptosContratados.find(
            ({ concept, price }) => concept === concepto && price
          )

          if (conceptoContratado)
            subObjeto[concepto] = {
              price: conceptoContratado.price,
              discountAmount: conceptoContratado.discountAmount,
              discountPercentage: conceptoContratado.discountPercentage,
            }

          // Añadimos el campo "packages" solo si existe en conceptoContratado
          if (conceptoContratado?.packages)
            subObjeto[concepto].packages = conceptoContratado.packages

          return subObjeto
        }, {})

        // Añadimos las licencias de cara a las llamadas al DMS
        dataCreate.planBilling.licencia = new Date(
          this.goodOkCuota.endDate + 'T' + toLocaleISOString().slice(11, 19)
        )

        if (this.notificationsSelected) {
          dataCreate.notificacionesActivas = true
          const notificationsPackages = this.conceptosContratados.find(
            ({ concept }) => concept === 'notificationsPackages'
          )
          const notificationsCuota = this.conceptosContratados.find(
            ({ concept }) => concept === 'notificationsCuota'
          )
          const notificationsAmount = notificationsPackages.packages.reduce(
            (i, elemento) => i + elemento,
            0
          )

          dataCreate.planBilling.licencia_notificaciones = {
            licensed: `${notificationsAmount}`,
            clients: `${notificationsCuota?.amount ?? 0}`,
          }
        }

        // Añadimos las licencias de cara a las llamadas al DMS (temporal hasta la activación)
        if (this.signaturesSelected) {
          dataCreate.firmasActivas = true
          const signaturesPackages = this.conceptosContratados.find(
            ({ concept }) => concept === 'signaturesPackages'
          )
          const signaturesCertsPackages = this.conceptosContratados.find(
            ({ concept }) => concept === 'signaturesCertsPackages'
          )
          const signaturesAmount = signaturesPackages.packages.reduce(
            (acumulador, elemento) => acumulador + elemento,
            0
          )
          const signaturesCertAmount = signaturesCertsPackages.packages.reduce(
            (acumulador, elemento) => acumulador + elemento,
            0
          )

          dataCreate.planBilling.licencia_firmas = {
            licensed: `${signaturesAmount}`,
            clients: `${signaturesCertAmount}`,
          }
        }

        if (this.termsHaveAcceptSelected) {
          dataCreate.legal = {
            haveAccept: true,
            contractModel: this.contractModelSelected,
          }
        }
        if (this.cliente.trialLicense) {
          dataCreate.trialLicense = true
        }

        // Llamamos a la funcion de crear EMPRESA
        const userCreated = await createEmpresaFirestore(dataCreate)

        // Redirigimos al listado de clientes
        this.$router.replace('/clients/' + userCreated.data.uid)

        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('client.createClient.createSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })
      } catch (error) {
        this.confirmLoading = false

        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      } finally {
        this.confirmLoading = false
        this.setLoading(false)
      }
    },
    notificationsPackagesEvent(newValue) {
      this.notificationsPackages = newValue
    },
    notificationsCuotaEvent(newValue) {
      this.notificationsCuota = newValue
    },
    signaturesCertsPackagesEvent(newValue) {
      this.signaturesCertsPackages = newValue
    },
    signaturesPackagesEvent(newValue) {
      this.signaturesPackages = newValue
    },
    goodOkCuotaEvent(newValue) {
      this.goodOkCuota = newValue
    },
    selectTermsHaveAcceptEvent() {
      this.termsHaveAcceptSelected = !this.termsHaveAcceptSelected
      if (!this.termsHaveAcceptSelected) {
        this.contractModelSelected = ''
      }
    },
    contractModelEvent(newValue) {
      this.contractModelSelected = newValue
    },
    selectProductNotificationsEvent() {
      this.notificationsSelected = !this.notificationsSelected

      if (!this.notificationsSelected) {
        this.notificationsCuota = {
          price: 0,
          discountAmount: 0,
          discountPercentage: 0,
        }

        this.notificationsPackages = {
          price: 0,
          discountAmount: 0,
          discountPercentage: 0,
        }
      }
    },
    selectProductSignaturesEvent() {
      this.signaturesSelected = !this.signaturesSelected

      if (!this.signaturesSelected) {
        this.signaturesCertsPackages = {
          price: 0,
          discountAmount: 0,
          discountPercentage: 0,
        }

        this.signaturesPackages = {
          price: 0,
          discountAmount: 0,
          discountPercentage: 0,
        }
      }
    },
    customServicesEvent(newValue) {
      const securityPackage = newValue.find(
        (service) => service.concept === 'multiFactor'
      )
      this.securityPackage = securityPackage.selected
        ? securityPackage
        : {
            price: 0,
            discountAmount: 0,
            discountPercentage: 0,
          }
      const customBrandPackage = newValue.find(
        (service) => service.concept === 'customBrand'
      )
      this.customBrandPackage = customBrandPackage.selected
        ? customBrandPackage
        : {
            price: 0,
            discountAmount: 0,
            discountPercentage: 0,
          }
      const absencesPackage = newValue.find(
        (service) => service.concept === 'absences'
      )
      this.absencesPackage = absencesPackage.selected
        ? absencesPackage
        : {
            price: 0,
            discountAmount: 0,
            discountPercentage: 0,
          }
    },
  },
}
</script>

<style scoped>
.custom-label-color ::v-deep .v-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
